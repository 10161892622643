import { Input } from 'antd';
import Modal from 'antd/lib/modal/Modal'
import React, { Component } from 'react'
import Signable from './Signable';
import $ from 'jquery';
import _ from 'underscore';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';

const titleStyle = {
    fontSize: '18pt',
    color: '#4CA336'
};

const shareText = "Join me in supporting a more democratic Ontario by learning about and endorsing the Pro Rep Party of Ontario with your digital signature at https://prorep.party";

export default class ThankYouModal extends Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = { isOpen: true };
    }

    render() {
        return <Modal
            maskClosable={true}
            onCancel={() => this.setState({ isOpen: false })}
            closeIcon={false}
            width={"900px"}
            bodyStyle={{
                height: "240px",
                textAlign: 'center'
            }}
            footer={null}
            visible={this.props.isOpen && this.state.isOpen}>
            <div>
                <div style={titleStyle}>Thank you for supporting a more democratic Ontario!</div>
                <div style={{padding: '10px'}}>Please consider sharing, our democracy works best when everyone is involved.</div>
                <div style={{padding: '10px'}}>
                    <TwitterShareButton style={{padding: '10px'}} via={shareText} url={"https://prorep.party"} >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <FacebookShareButton style={{padding: '10px'}} quote={shareText} url={"https://prorep.party"}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <EmailShareButton style={{padding: '10px'}} subject="Please help me create a fairer Ontario..." body={shareText} url={"https://prorep.party"}>
                        <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                </div>
            </div>
        </Modal>
    }

}