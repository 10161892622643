import React, { Component } from 'react'

const containerWidth = 500;
const containerHeight = 200;

export default class Signable extends Component {

    componentDidMount() {
        var canvas = document.getElementById("sign-canvas");
        var context = canvas.getContext("2d");
        var boundings = canvas.getBoundingClientRect();
        var mouseX = 0;
        var mouseY = 0;
        context.strokeStyle = 'black'; // initial brush color
        context.lineWidth = 3; // initial brush width
        var isDrawing = false;

        function setMouseCoordinates(event) {
            mouseX = event.clientX - boundings.left;
            mouseY = event.clientY - boundings.top;
        }

        canvas.addEventListener('mousedown', function (event) {
            setMouseCoordinates(event);
            isDrawing = true;
            context.beginPath();
            context.moveTo(mouseX, mouseY);
        });



        canvas.addEventListener('mousemove', function (event) {
            setMouseCoordinates(event);
            if (isDrawing) {
                context.lineTo(mouseX, mouseY);
                context.stroke();
            }
        });



        canvas.addEventListener('mouseup', function (event) {
            setMouseCoordinates(event);
            isDrawing = false;
        });

        canvas.addEventListener('mouseleave', function (event) {
            isDrawing = false;
        });

        canvas.addEventListener("touchstart", function (e) {
            setMouseCoordinates(e);
            var touch = e.touches[0];
            var mouseEvent = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.dispatchEvent(mouseEvent);
        }, false);
        canvas.addEventListener("touchend", function (e) {
            var mouseEvent = new MouseEvent("mouseup", {});
            canvas.dispatchEvent(mouseEvent);
        }, false);
        canvas.addEventListener("touchmove", function (e) {
            var touch = e.touches[0];
            var mouseEvent = new MouseEvent("mousemove", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.dispatchEvent(mouseEvent);
        }, false);


        var clearButton = document.getElementById('clear');
        clearButton.addEventListener('click', function () {
            context.clearRect(0, 0, canvas.width, canvas.height);
        });

        document.body.addEventListener("touchstart", function (e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);
        document.body.addEventListener("touchend", function (e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);
        document.body.addEventListener("touchmove", function (e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);
    }

    render() {
        const containerStyle = {
            width: `${containerWidth}px`,
            height: `${containerHeight}px`,
            border: 'solid grey 2px',
            borderRadius: '15px',
            position: 'relative',
            left: '30px'
        };
        return (
            <div style={{ textAlign: 'center', left: '50px' }}>
                <div style={{ fontSize: '14pt', position: 'relative', marginLeft: '-675px' }}>
                    <div>Sign Below</div>
                    <button style={{position: 'relative', left: '400px'}} className="btn btn-info" id="clear">Clear</button>
                </div>
                <div style={{
                    position: 'relative', width: '400px', height: '1px', border: '1px dotted black',
                    top: '160px',
                    left: '80px'

                }}></div>
                <div style={containerStyle}>
                    <canvas id="sign-canvas" width="500" height="200"></canvas>
                </div>
            </div>

        )
    }
}
