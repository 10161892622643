import { Input } from 'antd';
import Modal from 'antd/lib/modal/Modal'
import React, { Component } from 'react'
import Signable from './Signable';
import $ from 'jquery';
import _ from 'underscore';

export default class SigningModal extends Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = { step: 1, showContactMsg: false, fillMsg: false };
        this.saveSig = this.saveSig.bind(this);
    }
    saveSig() {
        // TODO Make sure required fields are filled
        if (!$('#name-input').val() || !$('#last-name-input').val() ||
            !$('#address-input').val() || !$("#city-input").val() || !$("#postal-input").val()) {
            this.setState({ fillMsg: true });
            return;
        }
        if (!$("#phone-input").val() && !$("#email-input").val()) {
            this.setState({ showContactMsg: true });
            return;
        }
        this.setState({
            sigUrl: document.getElementById("sign-canvas").toDataURL(),
            step: 2,
            firstName: $('#name-input').val(),
            lastName: $('#last-name-input').val(),
            address: $('#address-input').val(),
            city: $("#city-input").val(),
            postal: $("#postal-input").val(),
            phone: $("#phone-input").val(),
            email: $("#email-input").val(),

        })
    }
    render() {

        const inputStyle = {
            width: '90%'
        };
        if (this.state.fillMsg) {
            _.extend(inputStyle, { border: '2px solid red' })
        }

        const firstNameStyle = {
            position: 'absolute',
            top: '450px',
            fontSize: '14pt',
            left: '215px',
        }

        const lastNameStyle = {
            position: 'absolute',
            top: '450px',
            fontSize: '14pt',
            left: '600px',
        }

        const addressStyle = {
            position: 'absolute',
            top: '481px',
            fontSize: '14pt',
            left: '216px',
        }

        const cityStyle = {
            position: 'absolute',
            top: '507px',
            fontSize: '14pt',
            left: '216px',
        }

        const postalStyle = {
            position: 'absolute',
            top: '507px',
            fontSize: '14pt',
            left: '490px',
        }

        const phoneStyle = {
            position: 'absolute',
            top: '508px',
            fontSize: '14pt',
            left: '650px',
        }

        const emailStyle = {
            position: 'absolute',
            top: '534px',
            fontSize: '14pt',
            left: '216px',
        }

        return (
            <Modal
                width={"900px"}
                bodyStyle={{
                    height: this.state.step === 1 ? "540px" : "1150px"
                }}
                okText={this.state.step === 1 ? "Review Form" : "Submit"}
                cancelText={this.state.step === 1 ? "Cancel" : "Go back"}
                onCancel={() => this.state.step === 1 ? this.props.cancelCallback() : this.setState({ step: 1 })}
                onOk={() => this.state.step === 1 ? this.saveSig() : this.props.submitCallback()}
                visible={this.props.isOpen}>
                <div style={{ textAlign: 'center' }}>
                    {this.state.fillMsg && this.state.step === 1 ?
                        <div style={{ color: 'red', display: 'inline-block' }}>Please complete required fields, these are required by Elections Ontario.</div> : ""}
                    {/* {this.state.step === 1 ? */}
                    <div style={{ display: this.state.step !== 1 ? "none" : "contents" }} className='container-fluid'>
                        <Signable />
                        <div style={{ padding: '12px' }} >
                            <div className="col-sm-6" style={{ float: 'left' }}>
                                <div>First Name</div>
                                <Input id='name-input' style={inputStyle} />
                            </div>
                            <div style={{ float: 'left' }} className="col-sm-6">
                                <div>Last Name</div>
                                <Input id='last-name-input' style={inputStyle} />
                            </div>
                        </div>
                        <div style={{ padding: '12px' }}>
                            <div className="col-sm-6" style={{ float: 'left' }}>
                                <div>Address</div>
                                <Input id='address-input' style={inputStyle} />
                            </div>
                            <div className="col-sm-6" style={{ float: 'left' }}>
                                <div>City</div>
                                <Input id='city-input' style={inputStyle} />
                            </div>
                        </div>
                        <div style={{ padding: '12px' }}>
                            <div className='col-sm-6' style={{ float: 'left' }}>
                                <div>Postal Code</div>
                                <Input id='postal-input' style={inputStyle} />
                            </div>
                            <div className='col-sm-6' style={{ float: 'left' }}>
                                <div>Telephone Number</div>
                                <Input id='phone-input' style={inputStyle} />
                            </div>
                        </div>
                        {this.state.showContactMsg && this.state.step === 1 ?
                            <div style={{ color: 'red', display: 'inline-block' }}>Please provide either phone number or email in case Elections Ontario needs to verify your endorsement.</div> : ""}
                        <div style={{ padding: '12px' }}>
                            <div>Email</div>
                            <Input id='email-input' style={{ width: '90%' }} />
                        </div>
                    </div>
                    {/* : ""} */}

                    {this.state.step === 2 ?
                        <div id="html2canvas-target">
                            <div style={firstNameStyle}>{this.state.firstName}</div>
                            <div style={lastNameStyle}>{this.state.lastName}</div>
                            <div style={addressStyle}>{this.state.address}</div>
                            <div style={cityStyle}>{this.state.city}</div>
                            <div style={postalStyle}>{this.state.postal}</div>
                            <div style={phoneStyle}>{this.state.phone}</div>
                            <div style={emailStyle}>{this.state.email}</div>
                            <img style={{
                                position: 'absolute',
                                top: '535px',
                                width: '200px',
                                left: '330px'
                            }} src={this.state.sigUrl} />
                            <img src="/partiallyfilled-Form P-4.png" width="850" height="1100" />
                        </div>


                        : ""}


                </div>

            </Modal>


        )
    }
}
