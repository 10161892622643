import { useState, Fragment, lazy } from "react";
import { Row, Col, Drawer } from "antd";
import i18n from "i18next";
import SigningModal from "../SigningModal.js";
import ThankYouModal from "../ThankYouModal.js";
import { withTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import html2canvas from "html2canvas";
import AWS from 'aws-sdk';


import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Button = lazy(() => import("../../common/Button"));

const Header = ({ t }) => {

  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [signingModalOpen, toggleModal] = useState(false);
  const [thankYouModalOpen, toggleThankYouModal] = useState(false);
  const [visible, setVisibility] = useState(false);

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const openModal = () => {
    console.log("click");
    console.log(signingModalOpen);
    toggleModal(!signingModalOpen);
    console.log(signingModalOpen);
  };

  const openThankYouModal = () => {
    toggleThankYouModal(!thankYouModalOpen);    
  };

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id) => {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <Fragment>
        {/* <S.CustomNavLinkSmall>
          <S.Select>
                    <S.Label htmlFor="select-lang">{t("Language")}</S.Label>
                    <S.LangSelect
                      onChange={handleChange}
                      value={i18n.language}
                      id="select-lang"
                    >
                      <option value="en">English</option>
                      <option value="fr">Français</option>
                    </S.LangSelect>
          </S.Select>
        </S.CustomNavLinkSmall> */}
        <S.CustomNavLinkSmall onClick={() => scrollTo("faq")}>
          <S.Span>{t("FAQ")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <S.Span>{t("Mission")}</S.Span>
        </S.CustomNavLinkSmall>

        {/* <S.CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <S.Span>{t("Donate")}</S.Span>
        </S.CustomNavLinkSmall> */}
        <S.CustomNavLinkSmall
          style={{ width: window.innerWidth > 800 ? "180px" : '' }}
        // onClick={() => scrollTo("contact")}
        >
          <S.Span>
            {/* <form target="_blank" method="get" action="/partiallyfilled-Form P-4.pdf"> */}
            <Button onClick={openModal}>{t("Endorse")}</Button>
            {/* </form> */}
          </S.Span>
        </S.CustomNavLinkSmall>
      </Fragment>
    );
  };

  return (
    <S.Header id="intro">
      <ThankYouModal isOpen={thankYouModalOpen} />
      <SigningModal
        submitCallback={() => {
          console.log("submit");
          html2canvas(document.getElementById("html2canvas-target")).then(function (canvas) {
            var albumBucketName = "prorepparty-forms";
            var bucketRegion = "ca-central-1";
            var IdentityPoolId = "ca-central-1:c9a5a7d3-70e3-499b-81fa-4b80f17db03d";

            AWS.config.update({
              region: bucketRegion,
              credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IdentityPoolId
              })
            });

            var s3 = new AWS.S3({
              apiVersion: "2006-03-01",
              params: { Bucket: albumBucketName }
            });
            const photoKey = new Date().getTime() + ".png";
            document.body.appendChild(canvas);
            
            canvas.toBlob((blob) => {
            
            const file = new File([blob], photoKey, {
              type: "image/png"
            });
            var upload = s3.upload({
              Key: photoKey,
              Body: file
            });

            var promise = upload.promise();

            promise.then(
              function (data) {
                console.log(data);
              },
              function (err) {

                console.error(err.message);
              }
            );
            // TODO make modal show success and thank you message
            openModal();
            openThankYouModal();


          }, "image/png")
           
          });

        }}
        cancelCallback={() => {
          openModal();
        }}       
        isOpen={signingModalOpen} />
      <S.Container>
        <Row type="flex" justify="space-between" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            {/* <SvgIcon src="logo.svg" /> */}
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer style={{ color: 'black!important' }} closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
