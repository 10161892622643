import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";

import routes from "./config";
import GlobalStyles from "../globalStyles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SvgIcon from "../common/SvgIcon";
import { FaPen, FaPrint, FaSign, FaSignature } from 'react-icons/fa';

const Router = () => {
  return (
    <Suspense fallback={null}>
      <GlobalStyles />
      <Header />
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: '42pt' }}>Pro Rep Party<SvgIcon style={{float: 'left'}} src={'trillium-1.png'} width="60px" height="60px" /></h1>
        <h1 style={{ fontSize: '22pt' }}>The Proportional Representation Party of Ontario</h1>
        <Carousel stopOnHover={false}
        emulateTouch={false}
        renderThumbs={() => []}
        interval={6500}
        infiniteLoop={false} autoPlay={true}>
          <div style={{textAlign: 'center'}}>
            <img style={{width: '40%'}} src={`/img/carousel/carousel1.png`} />
            
            <div style={{fontSize: '28pt', color: 'black', padding: '20px'}}>
              We need to fix our <a target="_blank" href='https://medium.com/canadian-advocate/our-elections-are-broken-abb6f37c3353'>broken elections</a>
            {/* <p className="legend">Legend 1</p> */}
            </div>
          </div>
          <div style={{backgroundColor: 'rgba(255,255,255,0.3', borderRadius: '9px'}}>
            <div style={{color: 'black', fontSize: '30pt', padding: '40px'}}>
            UK’s Electoral Reform Society released a <a target="_blank" href="https://www.electoral-reform.org.uk/here-to-stay-two-decades-of-proportional-representation-in-britain">report</a> that showed Pro Rep Scottish elections were <b>FOUR TIMES</b> fairer than FPTP (First Past the Post)
            </div>
            {/* <p className="legend">Legend 2</p> */}
          </div>
          <div style={{backgroundColor: 'rgba(255,255,255,0.3', borderRadius: '9px'}}>
            <div style={{fontSize: '56pt', color: 'black', paddingLeft: '25px', paddingRight: '25px'}}>
              The Last Strategic Vote You Will Ever Need
              <div style={{fontSize: '30pt'}}><a target="_blank" href="https://www.fairvote.ca/a-look-at-the-evidence/">Learn More</a></div>
              </div>
          </div>
          
        </Carousel>
        <div style={{ textAlign: 'center' }}>
          <h2 style={{fontSize: '26pt'}}>Help make the Pro Rep Party a reality with your endorsement!</h2>
          {/* <div style={{fontSize: '12pt', color: 'red'}}>(Must be a Canadian citizen living in Ontario eligible to vote)</div>
          <form target="_blank" method="get" action="/partiallyfilled-Form P-4.pdf">
          <button style={{
            cursor: 'pointer',
            color: '#4CA336',
            fontSize: '40pt', border: 'none', borderRadius: '8px'}}>
              <div style={{color: '#4CA336'}}>Endorse</div>
              <FaPen /></button>   
          </form>
          
          <h2 style={{fontSize: '22pt'}}>We need 1000 endorsements to register the party with Elections Ontario</h2> */}
         
         <h2 style={{fontSize: '22pt'}}>We need 1000 endorsements to register the party with Elections Ontario</h2>
        </div>
      </div>
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      {/* <div style={{textAlign: 'center', padding: '25px', fontSize: '24pt', backgroundColor: 'rgba(255,255,255,0.3)'}}>
        If you'd like to be kept informed on the status of the Pro Rep Party, please email <a href="mailto:tim@prorep.party">tim@prorep.party</a> with the subject line "Subscribe"
      </div> */}
      {/* <Footer /> */}
    </Suspense>
  );
};

export default Router;
